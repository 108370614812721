.body {
    background: linear-gradient(255.35deg, #DC3131 0.83%, rgba(255, 79, 79, 0) 108.93%), #FF5E56;
    box-shadow: 0px 0px 24px rgba(234, 0, 41, 0.33);
    border-radius: 6px;
    padding: 16px 0 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.body:hover {
    background: #EA0029;
    box-shadow: 0px 0px 24px rgba(234, 0, 41, 0.33);
    border-radius: 6px;
}

.disabled {
    background: #BEC5CC;
    border-radius: 6px;
}

.body text {
    font-family: Lab Grotesque;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;
    text-align: center;

    /* White */

    color: #FFFFFF;
}