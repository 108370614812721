.body {
    width: 100vw;
    max-width: 50%;
    height: 50%;
    background: white;
    border-radius: 30px;
    padding: 4%;
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
}

@media (max-width: 900px) {
    .body {
        max-width: none;
        height: 100%;
        border-radius: 0;
        padding: 0 4% 0 4%;
        justify-content: normal;
    }

}