.body {
    width: 12px;
    height: 12px;
    position: absolute;
    top: 22px;
    right: 22px;
}
.body img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}