.body {
    display: flex;
    border-bottom: 1px solid #DFE3E6;
}
.body text {
    font-family: Lab Grotesque;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 5px;

    color: #000000;
}